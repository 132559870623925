import React, { FC } from 'react';
import { CircularProgress, Button, ButtonProps } from '@mui/material';

interface TextProps extends ButtonProps {
  readonly isLoading: boolean;
  readonly disabled?: boolean;
  readonly startIcon?: React.ReactElement;
  readonly endIcon?: React.ReactElement;
  readonly variant?: 'text' | 'outlined' | 'contained';
  readonly onClick?: () => unknown;
  readonly text?: React.ReactNode;
  readonly textColor?: '';
  readonly color?: 'primary' | 'secondary';
  readonly className?: string;
  readonly size?: 'small' | 'medium' | 'large';
  readonly children?: React.ReactNode;
}

export const ProgressiveButton: FC<TextProps> = ({
  isLoading,
  text,
  color = 'primary',
  size,
  onClick,
  disabled,
  variant = 'contained',
  startIcon,
  endIcon,
  className,
  children,
  ...rest
}) => (
  <Button
    {...(variant && { variant })}
    {...(startIcon && { startIcon })}
    {...(endIcon && { endIcon })}
    {...(className && { className })}
    {...rest}
    disabled={disabled}
    color={color}
    onClick={onClick}
    size={size}
  >
    {isLoading ? <CircularProgress /> : children || text}
  </Button>
);
